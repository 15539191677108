<template>
  <div>
    <!-- DESKTOP -->
    <div class="document-details__desktop" v-if="!isMobile">
      <v-row justify="center">
        <v-col class="mt-14 pl-11 pr-11 text-left" cols="12">
          <h1 class="document-details__title">{{ documentName }}</h1>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12 pt-0" class="text-color px-10">
          <h2>FIRMA DE DOCUMENTO</h2>
        </v-col>
        <v-col cols="12" md="10" xs="10" sm="10" lg="7" xl="7" class="px-10">
          <template v-if="documentTypeDetected">
            <p class="text-info-1 text-left">
              <strong> Pasos para la firma del documento: </strong>
            </p>
            <template v-if="formDocument.is_multifirmas === 'true' && formDocument.is_external === 'true'">
              <p class="text-info-1 text-left">
                <strong> 1. </strong> Lea con atención el documento PDF que se muestra a continuación.
              </p>
              <p class="text-info-1 text-left">
              <span>Si no puede visualizar correctamente el documento, haga clic</span>
              <v-btn @click="download" class="pl-5 pr-5 mr-2 ml-2 document-details__button" small rounded
                style="color: #fff" color="#466BE3">
                aquí
              </v-btn>
              para descargarlo. Una vez finalice la lectura, vuelva a esta página para firmar.
              </p>
              <p class="text-info-1 text-left">

              </p>
              <p class="text-info-1 text-left">
                <strong> 2. </strong> Para firmar el documento:
              <ul class="text-info-1 text-left ml-8">
                <li>Primero, genere o seleccione su firma en el <strong>RECUADRO AZUL</strong> en la parte inferior.
                </li>
                <li>Luego, arrastre su firma al lugar donde desea posicionarla en el documento.</li>
                <li>Puede ajustar el tamaño de la firma usando los puntos de control en las esquinas.</li>
              </ul>
              </p>

              <p class="text-info-1 text-left">
                <strong> 3. </strong> Cuando esté satisfecho con la posición y tamaño de su firma, haga clic en el botón
                "Guardar".
              </p>
            </template>
            <template v-else>
              <p class="text-info-1 text-left">
                <strong> 1. </strong> Lea con atención el siguiente documento.
              </p>
              <p class="text-info-1 text-left">
                <strong> 2. </strong> En la parte inferior de esta página firme el
                documento en el <strong>RECUADRO AZUL</strong>.
              </p>
              <p class="text-info-1 text-left">
                <strong> 3. </strong> Si no presenta una correcta visualización
                del documento por favor haga clic
                <v-btn @click="download" class="pl-5 pr-5 mr-2 ml-2 document-details__button" small rounded
                  style="color: #fff" color="#466BE3">
                  <strong> aquí </strong>
                </v-btn>
                se generará una nueva ventana en el navegador o se descarga en su
                dispositivo. Una vez finalice la lectura, vuelva a esta página y
                firme en el <strong>RECUADRO AZUL</strong>.
              </p>
              <p class="text-info-1 text-left">
                <strong> 4. </strong> Preferiblemente genere la firma del
                documento desde un dispositivo móvil para una mayor facilidad en
                su firma.
              </p>
              <p class="text-info-1 text-left">
                <strong> 5. </strong> Finalmente de clic en guardar, para
                registrar la firma.
              </p>
              <p class="text-info-1 text-left">
                <strong> 6. </strong> El documento cuenta con una marca de agua
                ("documento solo de lectura"), una vez firmado, esta marca de agua
                desaparece.
              </p>
            </template>

            <p class="text-info-1 text-left">
              <strong>
                ¡Recuerde que para visualizar la firma debe aceptar términos y
                condiciones!
              </strong>
            </p>
            <p class="text-info-1 text-left" v-if="sendEmail">
              <strong> NOTA: </strong> Al momento de finalizar se enviará un
              correo electrónico al mail registrado en Mr Chispa con la copia del
              documento.
            </p>
          </template>
          <template v-else>
            <v-skeleton-loader type="article" class="mx-auto"></v-skeleton-loader>
          </template>
        </v-col>
      </v-row>

      <div class="app-content">
        <div class="pdf-container" ref="pdfContainer" style="position: relative;">
          <vue-pdf-embed ref="pdfRef" :source="pdfSource" :page="currentPage" @rendered="handlePdfRender"
            @loaded="handlePdfLoad" />

          <!-- Controles de paginación -->
          <div v-if="totalPages > 1" class="pagination-controls">
            <v-btn icon :disabled="currentPage <= 1" @click="currentPage--" color="#466BE3">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="mx-4">Página {{ currentPage }} de {{ totalPages }}</span>
            <v-btn icon :disabled="currentPage >= totalPages" @click="currentPage++" color="#466BE3">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>

          <!-- Firma arrastrable -->
          <div v-if="isDraggingStarted && imgSign" ref="signatureElement" class="signature-draggable" :style="{
            position: 'absolute',
            left: signaturePosition.x + 'px',
            top: signaturePosition.y + 'px',
            width: signatureDimensions.width + 'px',
            height: signatureDimensions.height + 'px',
            transform: 'none',
            zIndex: 1000,
          }" @mousedown="startDragging">
            <img :src="imgSign.signature" alt="Firma" style="width: 100%; height: 100%; user-select: none;"
              draggable="false" />
            <div v-for="handle in resizeHandles" :key="handle.position" class="resize-handle" :class="handle.position"
              @mousedown.stop="startResizing($event, handle.position)"></div>
          </div>
        </div>
      </div>

      <v-row justify="center">
        <transition name="slide-fade">
          <v-card elevation="0" class="document-details__card pa-6 slide-bottom" outlined v-if="validateTerms">
            <v-card-title class="pb-0 px-0">
              <v-checkbox v-model="disabledDesktop"
                :label="'Acepto las condiciones consignadas en el documento relacionado anteriormente'" color="#3957B9"
                class="mt-0 px-0 document-details__check"></v-checkbox>
            </v-card-title>
            <v-card-actions class="px-0">
              <v-btn @click="
                checkboxDesktop
                  ? (checkboxDesktop = false)
                  : ((checkboxDesktop = true), scrollToSignature());
              validateTerms = false;
              " class="pl-5 pr-5 mr-2 ml-2 document-details__button" rounded style="color: #fff" color="#466BE3"
                :disabled="!disabledDesktop" block>
                <strong> Firmar </strong>
              </v-btn>
            </v-card-actions>
          </v-card>
        </transition>
      </v-row>

      <v-row justify="center" class="mx-0">
        <template v-if="checkboxDesktop">
          <v-col cols="12" md="10" xs="10" sm="10" lg="7" xl="7" class="px-10 mx-0" data-signature-section>
            <p class="text-info-1 text-left">
              <strong> Seleccione firma: </strong>
              <v-btn v-if="!showSign" x-small outlined @click="showSign = true" class="mr-2">
                Generar nueva firma
              </v-btn>
              <v-btn v-if="!showSign" x-small outlined @click="$refs.fileInput.click()" class="ml-2">
                Subir imagen
              </v-btn>
              <input
                ref="fileInput"
                type="file"
                accept="image/png"
                style="display: none"
                @change="handleImageUpload"
              >
            </p>
            <v-alert v-if="showAlert" dense text type="info" class="mt-3">
              No se encontró una firma, por favor genere una nueva en el
              <strong>RECUADRO AZUL</strong> o suba una imagen PNG
            </v-alert>
            <template v-if="imgSign && imgSign.signature">
              <img v-if="!isDraggingStarted" style="width: auto; cursor: grab;" :src="imgSign.signature"
                alt="Firma del colaborador" @mousedown="isMultifirmas && formDocument.is_external === 'true' ? initDragSignature($event) : null"
                :style="{ cursor: isMultifirmas && formDocument.is_external === 'true' ? 'grab' : 'default' }" />
            </template>
            <template v-else>
              <v-alert dense text type="info" class="mt-3">
                No tiene una firma guardada. Por favor, genere una nueva firma.
              </v-alert>
              <div v-if="!showSign" class="text-center mt-4">
                <v-btn color="#466BE3" dark @click="showSign = true">
                  Generar firma
                </v-btn>
              </div>
            </template>
          </v-col>
        </template>
      </v-row>
      <template v-if="showSign && checkboxDesktop">
        <v-row justify="center" class="mx-0">
          <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
            <v-btn block outlined small color="#3957B9" @click="undoDesk">Deshacer</v-btn>
          </v-col>
          <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
            <v-btn outlined color="#3957B9" block small @click="clearDesk">limpiar</v-btn>
          </v-col>
          <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
            <v-btn outlined color="#3957B9" block small @click="saveSignatureOnly">Guardar firma</v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="mx-0">
          <v-col class="pt-3 mx-0" md="10" xs="10" sm="10" lg="6" xl="6">
            <vueSignature1 class="document-details__sign" ref="signature1" :sigOption="option" :h="'150px'"
              :disabled="disabled"></vueSignature1>
          </v-col>
        </v-row>
      </template>

      <v-row justify="center" class="mb-15 pb-10" v-if="checkboxDesktop">
        <v-col cols="12" md="10" xs="10" sm="10" lg="6" xl="6">
          <v-layout justify-end="">
            <v-btn
              :loading="loading"
              class="document-details__button"
              block=""
              @click="saveDesk()"
              rounded
              style="color: #fff"
              color="#466BE3"
              v-if="!(isMultifirmas && formDocument.is_external === 'true' && !isDraggingStarted)"
            >
              Guardar
            </v-btn>
            <v-alert
              v-else
              dense
              text
              type="info"
              class="mt-3"
            >
              Para firmar el documento, primero arrastre su firma al lugar deseado en el documento
            </v-alert>
          </v-layout>
        </v-col>
      </v-row>
    </div>

    <!-- MOBILE -->
    <div class="document-details__mobile pb-10" v-else>
      <v-row class="px-0 mx-0 mt-4">
        <v-col style="text-align: left" cols="12" class="py-4 px-0 index-f-sm">
          <div class="d-flex">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p class="text-title-1">
              <label style="padding-top: 5%" class="document-details__name-text txt-capitalize">
                {{ name }},
              </label>
              <label style="padding-top: 5%" class="document-details__name-text">
                acá encontrará
              </label>
            </p>
          </div>
          <p style="text-align: left" class="document-details__name-subtext mb-1 ml-9">
            Todos los documentos corporativos
          </p>
        </v-col>
        <v-col cols="12">
          <v-col cols="12 pt-0" class="text-color">
            <h2>FIRMA DE DOCUMENTO</h2>
          </v-col>
          <v-col cols="12" md="12" xs="12" sm="12" class="pb-0">
            <p class="text-info-1 text-left">
              <strong> Pasos para la firma del documento: </strong>
            </p>
            <p class="text-info-1 text-left">
              <strong> 1. </strong> Lea con atención el siguiente documento.
            </p>
            <p class="text-info-1 text-left">
              <strong> 2. </strong> En la parte inferior de esta página firme el
              documento en el <strong>RECUADRO AZUL</strong>.
            </p>
            <p class="text-info-1 text-left">
              <strong> 3. </strong> Si no presenta una correcta visualización
              del documento por favor haga clic
              <v-btn @click="download" class="pl-5 pr-5 mr-2 ml-2 document-details__button" small rounded
                style="color: #fff" color="#466BE3">
                <strong> aquí </strong>
              </v-btn>
              se generará una nueva ventana en el navegador o se descarga en su
              dispositivo. Una vez finalice la lectura, vuelva a esta página y
              firme en el <strong>RECUADRO AZUL</strong>.
            </p>
            <p class="text-info-1 text-left">
              <strong> 4. </strong> Preferiblemente genere la firma del
              documento desde un dispositivo móvil para una mayor facilidad en
              su firma.
            </p>
            <p class="text-info-1 text-left">
              <strong> 5. </strong> Finalmente de clic en guardar, para
              registrar la firma.
            </p>
            <p class="text-info-1 text-left">
              <strong> 6. </strong> El documento cuenta con una marca de agua
              ("documento solo de lectura"), una vez firmado, esta marca de agua
              desaparece.
            </p>
            <p class="text-info-1 text-left">
              <strong>
                ¡Recuerde que para visualizar la fima debe aceptar terminos y
                condiciones!
              </strong>
            </p>
            <p class="text-info-1 text-left mb-0" v-if="sendEmail">
              <strong> NOTA: </strong> Al momento de finalizar se enviará un
              correo electrónico al mail registrado en Mr Chispa con la copia
              del documento.
            </p>
          </v-col>
        </v-col>

        <v-col cols="12">
          <div class="app-content">
            <div class="pdf-container" ref="pdfContainer" style="position: relative;">
              <vue-pdf-embed ref="pdfRef" :source="pdfSource" :page="currentPage" @rendered="handlePdfRender"
                @loaded="handlePdfLoad" />

              <!-- Controles de paginación -->
              <div v-if="totalPages > 1" class="pagination-controls">
                <v-btn icon :disabled="currentPage <= 1" @click="currentPage--" color="#466BE3">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-4">Página {{ currentPage }} de {{ totalPages }}</span>
                <v-btn icon :disabled="currentPage >= totalPages" @click="currentPage++" color="#466BE3">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>

              <!-- Firma arrastrable -->
              <div v-if="isDraggingStarted && imgSign" ref="signatureElement" class="signature-draggable" :style="{
                position: 'absolute',
                left: signaturePosition.x + 'px',
                top: signaturePosition.y + 'px',
                width: signatureDimensions.width + 'px',
                height: signatureDimensions.height + 'px',
                transform: 'none',
                zIndex: 1000,
              }" @mousedown="startDragging">
                <img :src="imgSign.signature" alt="Firma" style="width: 100%; height: 100%; user-select: none;"
                  draggable="false" />
                <div v-for="handle in resizeHandles" :key="handle.position" class="resize-handle"
                  :class="handle.position" @mousedown.stop="startResizing($event, handle.position)"></div>
              </div>
            </div>
          </div>

          <v-row justify="center">
            <transition name="slide-fade">
              <v-card elevation="0" class="document-details__card pa-6 slide-bottom mb-14" outlined
                v-if="validateTerms">
                <v-card-title class="pb-0 px-0">
                  <v-checkbox v-model="disabledDesktop"
                    :label="'Acepto las condiciones consignadas en el documento relacionado anteriormente'"
                    color="#3957B9" class="mt-0 px-0 document-details__check"></v-checkbox>
                </v-card-title>
                <v-card-actions class="px-0">
                  <v-btn @click="
                    checkboxMobile
                      ? (checkboxMobile = false)
                      : (checkboxMobile = true);
                  validateTerms = false;
                  " class="pl-5 pr-5 mr-2 ml-2 document-details__button" rounded style="color: #fff" color="#466BE3"
                    :disabled="!disabledDesktop" block>
                    <strong> Firmar </strong>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </transition>
          </v-row>

          <v-row justify="center" class="mx-0">
            <v-col cols="12" class="mx-0" v-if="checkboxMobile">
              <p class="text-info-1 text-left">
                <strong> Seleccione firma: </strong>
                <v-btn v-if="!showSign" x-small outlined @click="showSign = true" class="mr-2">
                  Generar nueva firma
                </v-btn>
                <v-btn v-if="!showSign" x-small outlined @click="$refs.fileInputMobile.click()" class="ml-2">
                  Subir imagen
                </v-btn>
                <input
                  ref="fileInputMobile"
                  type="file"
                  accept="image/png"
                  style="display: none"
                  @change="handleImageUpload"
                >
              </p>
              <v-alert v-if="showAlert" dense text type="info" class="mt-3">
                No se encontró una firma, por favor genere una nueva en el
                <strong>RECUADRO AZUL</strong> o suba una imagen PNG
              </v-alert>
              <img style="width: auto" :src="imgSign.signature" alt="Firma del colaborador" 
                @mousedown="isMultifirmas && formDocument.is_external === 'true' ? initDragSignature($event) : null"
                :style="{ cursor: isMultifirmas && formDocument.is_external === 'true' ? 'grab' : 'default' }" />
            </v-col>
          </v-row>

          <template v-if="showSign && checkboxMobile">
            <v-row justify="center" class="mx-0">
              <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
                <v-btn block outlined small color="#3957B9" @click="undo">Deshacer</v-btn>
              </v-col>
              <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
                <v-btn outlined color="#3957B9" block small @click="clear">limpiar</v-btn>
              </v-col>
              <v-col class="pb-0 mx-0" cols="10" md="3" xs="3" sm="3" lg="3" xl="3">
                <v-btn outlined color="#3957B9" block small @click="saveSignatureOnly">Guardar firma</v-btn>
              </v-col>
            </v-row>
            <v-row justify="center" class="mx-0">
              <v-col class="pt-3 mx-0" md="10" xs="10" sm="10" lg="6" xl="6">
                <vueSignature class="document-details__sign" ref="signature" :sigOption="option" :h="'150px'"
                  :disabled="disabled"></vueSignature>
              </v-col>
            </v-row>
          </template>

          <v-row justify="center" class="mb-15 pb-10" v-if="checkboxMobile">
            <v-col cols="12" md="10" xs="10" sm="10" lg="6" xl="6">
              <v-layout justify-end="">
                <v-btn
                  :loading="loading"
                  class="document-details__button"
                  block=""
                  @click="saveDesk()"
                  rounded
                  style="color: #fff"
                  color="#466BE3"
                  v-if="(!isMultifirmas) || 
                        (isMultifirmas && !formDocument.is_external) || 
                        (isMultifirmas && formDocument.is_external === 'true' && isDraggingStarted)"
                >
                  Guardar
                </v-btn>
                <v-alert
                  v-else-if="isMultifirmas && formDocument.is_external === 'true'"
                  dense
                  text
                  type="info"
                  class="mt-3"
                >
                  Para firmar el documento, primero arrastre su firma al lugar deseado en el documento
                </v-alert>
              </v-layout>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="sign" persistent max-width="490" content-class="document-details__scroll-y-sign">
      <v-card>
        <v-card-title class="text-h5 document-details__project-sign">
          <v-layout justify-center> GUARDAR FIRMA </v-layout>
        </v-card-title>
        <v-card-text class="document-details__txt-project-sign">
          Desea guardar la firma para una próxima ocasión
        </v-card-text>
        <v-card-actions>
          <v-row class="document-details__saveSign">
            <v-col cols="12" md="6">
              <v-btn rounded outlined block @click="close()">No registrar firma</v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn rounded outlined block @click="saveSign()" :loading="buttonLoading">Registrar firma</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.newPassword" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center>
            AGREGAR CONTRASEÑA NUEVA PARA LOS DOCUMENTO
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0" style="text-align: initial">
            * Esta contraseña será usada para otros documentos que requieran una
            mayor seguridad.
          </p>
          <p class="mb-0" style="text-align: initial">
            * Si es la primera vez que ingresa, recuerde que la contraseña es su
            número de documento, para conrinuar actualice su contraseña.
          </p>
          <v-form ref="form">
            <v-text-field color="#466be3" v-model="formPass.password_current" label="Contraseña actual"
              @click:append="show2 = !show2" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'" :rules="[(v) => !!v || 'Este campo es obligatorio']"></v-text-field>
            <v-text-field color="#466be3" v-model="formPass.password" label="Nueva contraseña"
              @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'" :rules="[(v) => !!v || 'Este campo es obligatorio']"></v-text-field>
            <v-text-field color="#466be3" v-model="formPass.confirmation_password" label="Confirmar nueva contraseña"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'" :rules="[(v) => !!v || 'Este campo es obligatorio']"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn style="background-color: #fff" color="#466be3" outlined rounded block @click="preview">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="loadingAll" class="document-details__button" style="color: #fff" color="#466BE3" rounded
                block @click="updatePass()">
                ACTUALIZAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.password" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center> CONTRASEÑA PARA EL DOCUMENTO </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0">Este documento requiere contraseña</p>
          <v-form ref="form" @submit.prevent="getPdf">
            <v-text-field color="#466be3" v-model="formPass.password" label="Ingrese aquí su contraseña"
              @click:append="show = !show" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'" :rules="[(v) => !!v || 'Este campo es obligatorio']"
              @keydown.enter.prevent="getPdf"></v-text-field>

            <v-btn color="#466be3" text @click="(dialog.reset = true), (dialog.password = false)">
              <u> Resetear contraseña </u>
            </v-btn>
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn style="background-color: #fff" color="#466be3" outlined rounded block @click="preview">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="loadingAll" @click="getPdf()" class="document-details__button" style="color: #fff"
                color="#466BE3" rounded block>
                Validar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500" v-model="dialog.reset" persistent>
      <v-card elevation="0">
        <v-card-title class="document-details__txt_title_dialog">
          <v-layout justify-center>
            RESETEAR CONTRASEÑA DE DOCUMENTOS
          </v-layout>
        </v-card-title>
        <v-card-text>
          <p class="mb-0 mt-3">
            Tenga en cuenta que si decide restaurar su contraseña, ésta se
            actualizará a su número de identificación. Por motivos de seguridad
            le recomendamos que actualice su contraseña por una más segura.
            Además, se enviará una notificación al correo electrónico para
            informarle sobre el cambio en su cuenta.
          </p>

          <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="expiration_date" label="Fecha de expedición" prepend-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="expiration_date" @input="menu2 = false"></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-card-actions class="pb-6">
          <v-row>
            <v-col cols="6">
              <v-btn style="background-color: #fff" color="#466be3" outlined rounded block
                @click="(dialog.password = true), (dialog.reset = false)">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="loadingAll" @click="resetPass()" class="document-details__button" style="color: #fff"
                color="#466BE3" rounded block>
                RESETEAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alert :open="alert.open" :text="alert.txt" :title="alert.title" :type="alert.type" :redirect="alert.redirect"
      @close="handleAlertClose">
    </Alert>

    <v-overlay :value="loadingAll || isProcessingPdf">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ loadingMessage || $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import vueSignature from "vue-signature";
import vueSignature1 from "vue-signature";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";
export default {
  name: "app",
  components: {
    vueSignature,
    vueSignature1,
    VuePdfEmbed,
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      show: 1,
      loadingAll: false,
      loadingBtn: false,
      openConfirm: false,
      openImport: false,
      alertCopy: false,
      file: null,
      countryError: '',
      alert: {
        show: false,
        text: '',
        type: 'error'
      },
      formDocument: {
        document_id: null,
        nombre: "",
        document_type: "",
        document: "",
        coordinator_signature: null,
        send_duva: null,
        send_pbot: null,
        html: "",
        is_multifirmas: "false", // Inicializado como string
        is_external: "false"     // Inicializado como string
      },
      pdfSource: "",
      currentPage: 1,
      totalPages: 0,
      isDraggingStarted: false,
      imgSign: null,
      signaturePosition: { x: 0, y: 0 },
      signatureDimensions: { width: 200, height: 100 },
      documentName: "",
      sendEmail: false,
      validateTerms: true,
      checkboxDesktop: false,
      checkboxMobile: false,
      disabledDesktop: false,
      disabledMobile: false,
      isMobile: false,
      isMultifirmas: false,
      loadingMessage: "",
      isProcessingPdf: false,
      pdfLoadingProgress: 0,
      initialSignatureWidth: 0,
      pdfDimensions: {
        width: 0,
        height: 0
      },
      resizeHandles: [
        { position: 'top-left' },
        { position: 'top-right' },
        { position: 'bottom-left' },
        { position: 'bottom-right' }
      ],
      showSignature: false,
      isResizing: false,
      currentResizeHandle: null,
      startPosition: { x: 0, y: 0 },
      startDimensions: { width: 0, height: 0 },
      expiration_date: "",
      menu2: false,
      dialog: {
        password: false,
        newPassword: false,
        reset: false,
      },
      formPass: {
        password: "",
        confirmation_password: "",
        password_current: "",
      },
      show1: false,
      show2: false,
      buttonLoading: false,
      name: "",
      token: "",
      showAlert: false,
      showSign: false,
      sign: false,
      buttonLoading: false,
      loadingSteps: {
        generating: false,
        downloading: false,
        processing: false
      },
      documentTypeDetected: false,
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
    currentPage(newPage) {
      // Actualizar la página en los parámetros de firma
      if (this.signParameters) {
        this.signParameters.page = newPage;
      }

      // Limpiar la firma arrastrable al cambiar de página
      this.isDraggingStarted = false;
    }
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    resetPass() {
      var data = {
        expiration_date: this.expiration_date,
      };
      Api.Auth()
        .changePasswordDocument(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.dialog.reset = false;
            this.dialog.newPassword = true;

            this.alert.txt = res.data.message;
            this.alert.title = "Excelente";
            this.alert.type = "success";
            this.alert.redirect = "";
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    preview() {
      this.$router.push({ name: "documents" });
    },
    open(e) {
      console.log(e);
    },
    download() {
      const link = document.createElement("a");
      link.href = this.pdfSource;
      link.target = "_blank";
      link.download =
        "documentos-brm-" + new Date().toISOString().substr(0, 10) + ".pdf";
      link.click();
    },
    handlePdfRender() {
      this.showSignature = true;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    async getPdf() {
      try {
        this.loadingAll = true;
        this.isProcessingPdf = true;
        let response;

        // Si es documento multifirmas
        if (this.isMultifirmas) {
          this.loadingSteps.generating = true;
          this.loadingMessage = "Generando documento...";

          const data = {
            document_sign_id: parseInt(this.$route.params.id),
            dsu_id: parseInt(this.$route.params.docUser),
            manager_password: this.formPass.password
          };

          response = await Api.documentosMultiFirmas().previsualizarDocumento(this.token, data);

          // Verificar si hay error en la respuesta
          if (response.error || (response.data && response.data.error)) {
            throw new Error(response.error || response.data.error);
          }

          this.loadingSteps.generating = false;
          this.loadingSteps.downloading = true;
          this.loadingMessage = "Descargando documento...";

          // Precargar el PDF solo si no hubo error
          await this.preloadPdf(response.data);
          this.loadingSteps.downloading = false;

          this.loadingSteps.processing = true;
          this.loadingMessage = "Procesando documento...";

          this.pdfSource = response.data;
          this.dialog.password = false;
          this.dialog.newPassword = false;
          this.alert = {
            open: true,
            txt: response.message || "Documento cargado correctamente",
            title: "Excelente",
            type: "success",
            redirect: ""
          };
        } else {
          // Para documentos normales
          const data = {
            documentSignId: this.$route.params.id,
            document_user_id: this.$route.params.docUser,
            manager_password: this.formPass.password,
          };
          response = await Api.noAuth().getDocumentReference(data, this.token);

          if (response.data.cod === 0) {
            this.pdfSource = response.data.data;
            this.dialog.password = false;
            this.dialog.newPassword = false;
            this.alert = {
              open: true,
              txt: response.data.message,
              title: "Excelente",
              type: "success",
              redirect: ""
            };
          } else {
            throw new Error(response.data.message || 'Error al obtener el documento');
          }
        }
      } catch (error) {
        console.error('Error en getPdf:', error);
        this.pdfSource = null; // Limpiar el PDF si hay error
        this.dialog.password = true; // Mantener el diálogo de contraseña abierto
        this.formPass.password = ""; // Limpiar el campo de contraseña
        this.alert = {
          open: true,
          type: "error",
          txt: error.message || error.response?.data?.error || error.response?.data?.message || "Error al obtener el documento",
          title: "¡Oh no!",
          redirect: ""
        };
      } finally {
        this.loadingSteps = {
          generating: false,
          downloading: false,
          processing: false
        };
        this.isProcessingPdf = false;
        this.loadingAll = false;
        this.loadingMessage = "";
        this.pdfLoadingProgress = 0;
      }
    },
    async preloadPdf(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';

        xhr.onprogress = (event) => {
          if (event.lengthComputable) {
            this.pdfLoadingProgress = Math.round((event.loaded / event.total) * 100);
            this.loadingMessage = `Descargando documento... ${this.pdfLoadingProgress}%`;
          }
        };

        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve();
          } else {
            reject(new Error('Error al precargar el PDF'));
          }
        };

        xhr.onerror = () => reject(new Error('Error de red al precargar el PDF'));

        xhr.open('GET', url);
        xhr.send();
      });
    },
    async getDocument() {
      try {
        const data = {
          documentId: this.$route.params.id,
        };
        this.loadingAll = true;
        const res = await Api.noAuth().getDocumentById(data, this.token);

        if (res.data.cod === 0) {
          this.sendEmail = res.data.data.document_sign.send_duva;
          this.documentName = res.data.data.document_sign.nombre;
          this.formDocument.is_multifirmas = String(res.data.data.document_sign.document === 'multiples_firmas');
          this.formDocument.is_external = String(res.data.data.document_sign.documento_externo);
          this.isMultifirmas = res.data.data.document_sign.document === 'multiples_firmas';
          this.documentTypeDetected = true;
        }
      } catch (error) {
        this.alert = {
          open: true,
          type: 'error',
          txt: 'Error al obtener el documento',
          title: '¡Oh no!',
          redirect: ''
        };
      } finally {
        this.loadingAll = false;
      }
    },
    async handlePdfLoad(pdf) {
      try {
        // Obtener las dimensiones de la primera página
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.0 });

        this.pdfDimensions = {
          width: viewport.width,
          height: viewport.height
        };

        // Obtener el número total de páginas
        this.totalPages = pdf.numPages;

        // Asegurarnos de que currentPage sea válido
        this.currentPage = Math.min(Math.max(1, this.currentPage), this.totalPages);

        // Limpiar la posición de la firma al cambiar de página
        if (this.isDraggingStarted) {
          this.isDraggingStarted = false;
          this.signaturePosition = { x: 0, y: 0 };
        }
      } catch (error) {
        console.error('Error al cargar el PDF:', error);
        this.alert.open = true;
        this.alert.type = "error";
        this.alert.txt = "Error al cargar el documento PDF";
        this.alert.title = "¡Oh no!";
      }
    },
    convertViewportToPDFCoordinates(viewportX, viewportY, viewportWidth, viewportHeight) {
      // Obtener el elemento contenedor del PDF
      const pdfContainer = document.querySelector('.pdf-container');
      if (!pdfContainer) return null;

      const containerWidth = pdfContainer.offsetWidth;
      const containerHeight = pdfContainer.offsetHeight;

      // Usar las dimensiones reales del PDF en lugar de valores fijos
      const pdfWidth = this.pdfDimensions.width;
      const pdfHeight = this.pdfDimensions.height;

      // Calcular las coordenadas en puntos PDF
      const x = Math.round((viewportX / containerWidth) * pdfWidth);
      const y = Math.round((viewportY / containerHeight) * pdfHeight);
      const width = Math.round((viewportWidth / containerWidth) * pdfWidth);
      const height = Math.round((viewportHeight / containerHeight) * pdfHeight);

      return { x, y, width, height };
    },
    async save() {
      try {
        if (!this.checkboxDesktop && !this.checkboxMobile) {
          throw new Error('Debe aceptar los términos y condiciones');
        }

        this.loadingAll = true;

        // Asegurarnos de tener las coordenadas correctas en puntos PDF
        this.convertViewportToPDFCoordinates();

        // Preparar datos para la API
        const data = {
          document_id: parseInt(this.$route.params.id),
          dsu_id: parseInt(this.$route.params.docUser),
          manager_password: this.formPass.password,
          document_sign_id: parseInt(this.$route.params.id),
          signature: this.imgSign,
          sign_parameters: this.signParameters
        };

        const response = await Api.documentosMultiFirmas().firmarDocumento(this.token, data);

        if (response && response.message) {
          this.loadingAll = false;
          this.alert = {
            open: true,
            title: "Excelente",
            txt: response.message || "Documento firmado correctamente",
            type: "success",
            redirect: "documents"
          };
        } else {
          throw new Error(response?.data?.error || 'Error al firmar el documento');
        }
      } catch (error) {
        console.error('Error al guardar firma:', error);
        this.loadingAll = false;
        this.alert.open = true;
        this.alert.title = "¡Oh no!";
        this.alert.txt = error.response?.data?.error || error.message || "Error al guardar la firma";
        this.alert.type = "error";
        this.alert.redirect = "";
      }
    },
    async saveDesk() {
      try {
        let png;
        let flag = false;

        if (this.$refs.signature1 && !this.$refs.signature1.isEmpty()) {
          png = this.$refs.signature1.save();
          this.imgSign = { signature: png };
          flag = true;
        } else if (this.imgSign && this.imgSign.signature) {
          png = this.imgSign.signature;
        } else {
          throw new Error('No se ha encontrado una firma válida');
        }

        if (!(this.checkboxDesktop || this.checkboxMobile)) {
          throw new Error('Debe aceptar los términos y condiciones');
        }

        this.loadingAll = true;

        if (this.isMultifirmas) {
          const data = {
            document_id: parseInt(this.$route.params.id),
            dsu_id: parseInt(this.$route.params.docUser),
            manager_password: this.formPass.password,
            document_sign_id: parseInt(this.$route.params.id),
            signature: png
          };

          if (this.formDocument.is_external === 'true' && this.isDraggingStarted) {
            if (!this.signParameters ||
              this.signParameters.x <= 0 ||
              this.signParameters.y <= 0 ||
              this.signParameters.width <= 0 ||
              this.signParameters.height <= 0) {
              throw new Error('Por favor, posicione la firma en el documento antes de guardar');
            }
            data.sign_parameters = this.signParameters;
          }

          const response = await Api.documentosMultiFirmas().firmarDocumento(this.token, data);

          if (response && response.message) {
            this.loadingAll = false;
            if (flag) {
              this.sign = true;
              this.showSign = false;
            } else {
              this.alert = {
                open: true,
                title: "Excelente",
                txt: response.message || "Documento firmado correctamente",
                type: "success",
                redirect: "documents"
              };
            }
          } else {
            throw new Error(response?.data?.error || 'Error al firmar el documento');
          }
        } else {
          const data = {
            documentSignId: this.$route.params.id,
            document_user_id: this.$route.params.docUser,
            img: png,
            manager_password: this.formPass.password,
          };

          const config = {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          };

          const response = await Api.Auth().saveSign(data, config);

          if (response.data.cod === 0) {
            this.loadingAll = false;
            if (flag) {
              this.sign = true;
              this.showSign = false;
            } else {
              this.alert = {
                open: true,
                title: "Excelente",
                txt: response.data.message || "Documento firmado correctamente",
                type: "success",
                redirect: "documents"
              };
            }
          } else {
            throw new Error(response.data.message || 'Error al guardar la firma');
          }
        }
      } catch (error) {
        this.loadingAll = false;
        this.alert.open = true;
        this.alert.title = "¡Oh no!";
        this.alert.txt = error.response?.data?.error || error.message || "Error al guardar la firma";
        this.alert.type = "error";
        this.alert.redirect = "";
      }
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    clearDesk() {
      var _this = this;
      _this.$refs.signature1.clear();
    },
    undoDesk() {
      var _this = this;
      _this.$refs.signature1.undo();
    },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
    async getCommissionsImg() {
      this.loadingAll = true;
      try {
        const res = await Api.MarginCommisions().getSign(this.token);
        this.loadingAll = false;
        this.imgSign = res.data.data;
        if (this.imgSign != null) {
          this.showSign = false;
        }
      } catch (error) {
        this.loadingAll = false;
        if (error.response && error.response.status === 404) {
          this.showSign = true;
          this.showAlert = true;
          this.imgSign = null;
        }
      }
    },
    close() {
      this.sign = false;
      this.alert = {
        open: true,
        title: "Excelente",
        txt: "El documento se firmó correctamente",
        type: "success",
        redirect: "documents"
      };
    },
    saveSign() {
      var _this = this;
      var png = null;
      if (screen.width >= 1023) {
        png = _this.$refs.signature1.save("image/png");
      } else {
        png = _this.$refs.signature.save("image/png");
      }
      this.buttonLoading = true;
      fetch(png)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "firma.png", { type: "image/png" });
          const formData = new FormData();
          formData.append("signature", file);
          formData.append("manager_password", this.formPass.password);
          this.loadingAll = true;
          Api.MarginCommisions()
            .saveSign(formData, this.token)
            .then((res) => {
              this.loadingAll = false;
              this.buttonLoading = false;
              this.getDocumentSign(res.data.message);
            })
            .catch((error) => {
              this.loadingAll = false;
              this.alert.open = true;
              this.alert.title = "¡Oh no!";
              this.alert.txt = "Intenta mas tarde...";
              this.alert.type = "error";
              this.alert.redirect = "";
            });
        });
    },
    getDocumentSign(message) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      };
      let documents = {
        completed: null,
        pending: null,
      };

      console.log("🚀 Iniciando getDocumentSign...");

      Api.Auth()
        .signCompleted(config)
        .then((res) => {
          if (res.data.cod == 0) {
            console.log("🔍 Documentos completados:", res.data.data);
            documents.completed = res.data.data;
            console.log("📄 Objeto documents actualizado:", documents);

            this.$store.dispatch("updateDocuments", documents);
            this.alert.open = true;
            this.alert.title = "Excelente";
            this.alert.txt = message;
            this.alert.type = "success";
            this.alert.redirect = "documents";

            Api.Auth()
              .signPending(config)
              .then((res) => {
                if (res.data.cod == 0) {
                  documents.pending = res.data.data;
                  console.log("📋 Estado final de documents:", documents);
                }
              })
              .catch((err) => {
                console.error("❌ Error obteniendo documentos pendientes:", err);
              });
          }
        })
        .catch((err) => {
          console.error("❌ Error obteniendo documentos completados:", err);
        })
        .finally(() => {
          console.log("✅ Proceso getDocumentSign finalizado");
        });
    },
    validatePass(origin) {
      this.loadingAll = true;
      var data = {
        document_id: this.$route.params.id,
        document_user_id: this.$route.params.docUser,
      };
      if (origin == 1) {
        data.manager_password = this.formPass.password;
      }

      Api.Auth()
        .validatePass(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            if (res.data.data.password_change_required) {
              this.dialog.newPassword = true;
            } else if (res.data.data.password_required) {
              if (res.data.data.url == null) {
                this.dialog.password = true;
              }
            } else {
              this.dialog.password = false;
              this.dialog.newPassword = false;
              this.getPdf();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    updatePass() {
      this.loadingAll = true;
      var data = {
        current_password: this.formPass.password_current,
        new_password: this.formPass.password,
        confirm_password: this.formPass.confirmation_password,
      };
      Api.Auth()
        .updatePass(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.getPdf();
            this.alert.open = true;
            this.alert.txt = res.data.message;
            this.alert.title = "Excelente";
            this.alert.type = "success";
            this.alert.redirect = "";
            this.dialog.password = false;
            this.dialog.newPassword = false;
          }
        })
        .catch((error) => {
          this.alert.open = true;
          this.alert.type = "error";
          this.alert.txt = error.response.data.message;
          this.alert.title = "¡Oh no!";
          this.alert.redirect = "";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    initDragSignature(event) {
      const img = event.target;
      const pdfContainer = this.$refs.pdfContainer;
      const pdfRect = pdfContainer.getBoundingClientRect();

      // Calcular dimensiones iniciales manteniendo la proporción
      this.initialSignatureWidth = Math.min(200, pdfRect.width * 0.3);
      const aspectRatio = img.naturalHeight / img.naturalWidth;

      this.signatureDimensions = {
        width: this.initialSignatureWidth,
        height: this.initialSignatureWidth * aspectRatio
      };

      // Posicionar en el centro del clic
      this.signaturePosition = {
        x: event.clientX - pdfRect.left - (this.initialSignatureWidth / 2),
        y: event.clientY - pdfRect.top - (this.signatureDimensions.height / 2)
      };

      this.isDraggingStarted = true;
      this.isDragging = true;

      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    startDragging(event) {
      if (this.isResizing) return;
      event.preventDefault();

      this.isDragging = true;
      const pdfRect = this.$refs.pdfContainer.getBoundingClientRect();

      this.startPosition = {
        x: event.clientX - pdfRect.left - this.signaturePosition.x,
        y: event.clientY - pdfRect.top - this.signaturePosition.y
      };

      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    onDrag(event) {
      if (!this.isDragging && !this.isResizing) return;
      event.preventDefault();

      const pdfRect = this.$refs.pdfContainer.getBoundingClientRect();

      if (this.isDragging) {
        // Calcular nueva posición relativa al contenedor PDF
        let newX = event.clientX - pdfRect.left - this.startPosition.x;
        let newY = event.clientY - pdfRect.top - this.startPosition.y;

        // Limitar al área del PDF
        newX = Math.max(0, Math.min(newX, pdfRect.width - this.signatureDimensions.width));
        newY = Math.max(0, Math.min(newY, pdfRect.height - this.signatureDimensions.height));

        this.signaturePosition = { x: newX, y: newY };
      } else if (this.isResizing) {
        const deltaX = event.clientX - (pdfRect.left + this.startPosition.x);
        const deltaY = event.clientY - (pdfRect.top + this.startPosition.y);
        const aspectRatio = this.startDimensions.height / this.startDimensions.width;

        let newWidth = this.startDimensions.width;
        let newHeight = this.startDimensions.height;
        let newX = this.signaturePosition.x;
        let newY = this.signaturePosition.y;

        switch (this.currentResizeHandle) {
          case 'bottom-right':
            newWidth = Math.max(50, this.startDimensions.width + deltaX);
            newHeight = newWidth * aspectRatio;
            break;
          case 'bottom-left':
            newWidth = Math.max(50, this.startDimensions.width - deltaX);
            newHeight = newWidth * aspectRatio;
            newX = this.startPosition.x + this.startDimensions.width - newWidth;
            break;
          case 'top-right':
            newWidth = Math.max(50, this.startDimensions.width + deltaX);
            newHeight = newWidth * aspectRatio;
            newY = this.startPosition.y + this.startDimensions.height - newHeight;
            break;
          case 'top-left':
            newWidth = Math.max(50, this.startDimensions.width - deltaX);
            newHeight = newWidth * aspectRatio;
            newX = this.startPosition.x + this.startDimensions.width - newWidth;
            newY = this.startPosition.y + this.startDimensions.height - newHeight;
            break;
        }

        // Verificar límites del PDF
        if (newX >= 0 && newX + newWidth <= pdfRect.width &&
          newY >= 0 && newY + newHeight <= pdfRect.height) {
          this.signatureDimensions = { width: newWidth, height: newHeight };
          this.signaturePosition = { x: newX, y: newY };
        }
      }
    },
    stopDragging(event) {
      if (!this.isDragging && !this.isResizing) return;
      if (event) event.preventDefault();

      const signatureElement = document.querySelector('.signature-draggable');
      if (!signatureElement) return;

      const pdfContainer = this.$refs.pdfContainer;
      if (!pdfContainer) return;

      // Obtener posición y dimensiones actuales
      const rect = signatureElement.getBoundingClientRect();
      const containerRect = pdfContainer.getBoundingClientRect();

      // Calcular posición relativa al contenedor
      const relativeX = rect.left - containerRect.left;
      const relativeY = rect.top - containerRect.top;

      // Convertir coordenadas del viewport a coordenadas PDF
      const pdfCoordinates = this.convertViewportToPDFCoordinates(
        relativeX,
        relativeY,
        rect.width,
        rect.height
      );

      if (pdfCoordinates) {
        this.signParameters = {
          x: Math.max(1, pdfCoordinates.x),
          y: Math.max(1, pdfCoordinates.y),
          width: Math.max(1, pdfCoordinates.width),
          height: Math.max(1, pdfCoordinates.height),
          page: this.currentPage || 1
        };
      }

      this.isDragging = false;
      this.isResizing = false;
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDragging);
    },
    startResizing(event, handlePosition) {
      event.preventDefault();
      event.stopPropagation();

      this.isResizing = true;
      this.currentResizeHandle = handlePosition;

      const pdfRect = this.$refs.pdfContainer.getBoundingClientRect();

      this.startPosition = {
        x: this.signaturePosition.x,
        y: this.signaturePosition.y
      };

      this.startDimensions = {
        width: this.signatureDimensions.width,
        height: this.signatureDimensions.height
      };

      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDragging);
    },
    async saveSignatureOnly() {
      try {
        if (!this.$refs.signature1 || this.$refs.signature1.isEmpty()) {
          throw new Error('Por favor, dibuje una firma antes de guardar');
        }

        const png = this.$refs.signature1.save();

        const response = await fetch(png);
        const blob = await response.blob();
        const file = new File([blob], 'firma.png', { type: 'image/png' });

        const formData = new FormData();
        formData.append('signature', file);
        formData.append('manager_password', this.formPass.password);

        const apiResponse = await Api.MarginCommisions().saveSign(formData, this.token);

        if (apiResponse.data.cod === 1 && apiResponse.data.message) {
          this.imgSign = { signature: png };

          this.showAlert = false;
          this.showSign = false;

          this.alert = {
            open: true,
            title: "Excelente",
            txt: "Firma guardada correctamente",
            type: "success",
            redirect: ""
          };

          if (this.formDocument && this.formDocument.is_multifirmas === 'true' && this.formDocument.is_external === 'true') {
            setTimeout(() => {
              this.alert = {
                open: true,
                title: "Información",
                txt: "Ahora puede arrastrar su firma al documento",
                type: "info",
                redirect: ""
              };
            }, 2000);
          }

          await this.getCommissionsImg();
        }
      } catch (error) {
        this.alert.open = true;
        this.alert.title = "¡Oh no!";
        this.alert.txt = error.response?.data?.message || error.message || "Error al guardar la firma";
        this.alert.type = "error";
        this.alert.redirect = "";
      }
    },
    handleAlertClose() {
      this.alert = {
        ...this.alert,
        open: false
      };

      setTimeout(() => {
        if (this.alert.redirect) {
          this.$router.push({ name: this.alert.redirect })
            .catch(err => {
              console.error('Error en redirección:', err);
            });
        }
      }, 100);
    },
    scrollToSignature() {
      // Esperar a que Vue actualice el DOM
      this.$nextTick(() => {
        const signatureSection = document.querySelector('.document-details__sign') || 
                               document.querySelector('[data-signature-section]');
        if (signatureSection) {
          signatureSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
    async handleImageUpload(event) {
      try {
        const file = event.target.files[0];
        
        if (!file) {
          return;
        }

        // Validar que sea un archivo PNG
        if (file.type !== 'image/png') {
          this.alert = {
            open: true,
            type: 'error',
            txt: 'Solo se permiten archivos PNG',
            title: '¡Oh no!',
            redirect: ''
          };
          return;
        }

        // Validar tamaño del archivo (máximo 2MB)
        if (file.size > 2 * 1024 * 1024) {
          this.alert = {
            open: true,
            type: 'error',
            txt: 'La imagen no debe superar los 2MB',
            title: '¡Oh no!',
            redirect: ''
          };
          return;
        }

        this.loadingAll = true;

        // Crear un objeto Image para obtener las dimensiones
        const img = new Image();
        img.src = URL.createObjectURL(file);

        // Esperar a que la imagen se cargue
        await new Promise((resolve, reject) => {
          img.onload = resolve;
          img.onerror = reject;
        });

        // Validar que la imagen sea rectangular (más ancha que alta)
        if (img.width <= img.height) {
          this.alert = {
            open: true,
            type: 'error',
            txt: 'La imagen debe ser rectangular (más ancha que alta)',
            title: '¡Oh no!',
            redirect: ''
          };
          return;
        }

        // Crear FormData con la imagen original
        const formData = new FormData();
        formData.append('signature', file);
        formData.append('manager_password', this.formPass.password);

        // Usar el endpoint para guardar firmas
        const response = await Api.MarginCommisions().saveSign(formData, this.token);

        if (response.data.cod === 1 && response.data.message) {
          // Convertir el archivo a base64 para mostrarlo
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imgSign = { signature: e.target.result };
            this.showAlert = false;
            this.showSign = false;
          };
          reader.readAsDataURL(file);

          this.alert = {
            open: true,
            title: "Excelente",
            txt: "Imagen guardada como firma correctamente",
            type: "success",
            redirect: ""
          };

          if (this.formDocument && this.formDocument.is_multifirmas === 'true' && this.formDocument.is_external === 'true') {
            setTimeout(() => {
              this.alert = {
                open: true,
                title: "Información",
                txt: "Ahora puede arrastrar su firma al documento",
                type: "info",
                redirect: ""
              };
            }, 2000);
          }

          await this.getCommissionsImg();
        }
      } catch (error) {
        console.error('Error al subir imagen:', error);
        this.alert = {
          open: true,
          type: 'error',
          txt: error.response?.data?.message || 'Error al subir la imagen',
          title: '¡Oh no!',
          redirect: ''
        };
      } finally {
        this.loadingAll = false;
        // Limpiar el input file
        event.target.value = '';
      }
    },
  },
  async mounted() {
    try {
      await this.getToken();
      this.checkIfMobile();
      await this.getCommissionsImg();
      await this.getDocument();
      await this.validatePass(null);
    } catch (error) {
      this.alert = {
        open: true,
        type: 'error',
        txt: 'Error al cargar el documento. Por favor, intente nuevamente.',
        title: '¡Oh no!',
        redirect: ''
      };
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.document-details__sign {
  border: 1.3px solid;
  border-color: #3a57b9;
}

.document-details__project-sign {
  color: #466be3;
  font-family: "RobotoMedium" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
  font-size: 2rem !important;
}

.document-details__txt_title_dialog {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #353535;
  word-break: break-word;
}

.document-details__txt-project-sign {
  font-family: "RobotoRegular";
  color: #898989 !important;
  font-weight: 300;
  font-size: 1rem;
}

.document-details__scroll-y-sign {
  box-shadow: none !important;
  overflow-y: inherit !important;
}

.document-details__title {
  word-break: break-word;
}

.document-details__desktop {
  display: block;
}

.document-details__mobile {
  display: none;
}

.document-details__name-text {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.document-details__name-subtext {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "RobotoRegular";
}

.theme--light.v-label {
  color: #353535;
  line-height: 1.5;
}

.document-details__display-hidden {
  display: none;
}

.document-details__display-visible {
  display: block;
}

.document-details__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.document-details__button:hover {
  background-color: #324ca1 !important;
}

.document-details__check {
  font-family: "RobotoRegular";
}

.document-details__card {
  font-family: "RobotoRegular";
  border-radius: 15px !important;
  position: fixed;
  bottom: 20px;
  z-index: 100;
}

.document-details__card:hover {
  box-shadow: 0px 2px 4px rgba(62, 123, 250, 0.4),
    0px 8px 16px rgba(62, 123, 250, 0.16) !important;
  border-radius: 10px !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

/* LAPTOP 4k */

@media (max-width: 2000px) {}

/* LAPTOP LARGE */

@media (max-width: 1600px) {}

/* LAPTOP LARGE */

@media (max-width: 1400px) {}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {}

/* TABLET */

@media (max-width: 1023px) {
  .document-details__desktop {
    display: none;
  }

  .document-details__mobile {
    display: block;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .document-details__saveSign {
    flex-direction: column;
    align-items: center;
  }
}

/* MOBILE M */
@media (max-width: 375px) {}

/* MOBILE S */
@media (max-width: 320px) {}

.signature-draggable {
  position: absolute;
  cursor: move;
  z-index: 100;
}

.signature-draggable.dragging {
  opacity: 0.8;
  pointer-events: none;
}

/* Aseguramos que el overlay esté por encima de todo */
.v-overlay {
  z-index: 999 !important;
}

.v-dialog {
  z-index: 1000 !important;
}

/* Cuando el overlay está activo, ocultamos la firma arrastrable */
.v-overlay--active~.signature-draggable {
  display: none;
}

/* Estilos para el contenedor del PDF */
.pdf-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Estilos para los manejadores de redimensionamiento */
.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #466BE3;
  border: 2px solid white;
  border-radius: 50%;
  z-index: 101;
}

.resize-handle.top-left {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}

.resize-handle.top-right {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.resize-handle.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.resize-handle.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: se-resize;
}

/* Aseguramos que los diálogos y overlays estén siempre por encima */
.v-dialog__content {
  z-index: 1000 !important;
}

.v-overlay__content {
  z-index: 1001 !important;
}

/* Estilos para los controles de paginación */
.pagination-controls {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  padding: 8px 16px;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.pagination-controls span {
  font-family: "RobotoRegular";
  color: #466BE3;
}
</style>
